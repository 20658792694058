/**
 * TABLA ADMINISTRACION DE USUARIOS INTERNOS
*/
import React from 'react';
import DOMPurify from 'dompurify';
import DataGrid, {
    Column,
    Paging,
    SearchPanel,
    Pager,
    HeaderFilter,
    Button,
    Selection,
    Export,
    Scrolling,
} from 'devextreme-react/data-grid';
import 'devextreme/dist/css/dx.softblue.css';
import { exportDataGrid } from 'devextreme/excel_exporter';
import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver-es';
import Modal from "react-bootstrap/Modal";
import Swal from 'sweetalert2/dist/sweetalert2.js'
import 'sweetalert2/src/sweetalert2.scss'
import {API_PATH } from '../../siteConfig';
import RegistrarUsuarioInterno from './RegistrarUsuarioInterno.js';
import EditarUsuarioInterno from './EditarUsuarioInterno.js';


const allowedPageSizes = [20, 40, 60, 80, 100,'all'];//configura el número de filas que se mostraran en la tabla

class TablaUsuarioInternos extends React.Component {
    constructor(props) {
        super(props);
        this.applyFilterTypes = [{
            key: 'auto',
            name: 'Immediately'
        }, {
            key: 'onClick',
            name: 'On Button Click'
        }];
        this.state = {
            selectAllCheckBox: null,
            checkBoxUpdating : false,
            //ATRIBUTOS PARA MOSTRAR PAGINACION EN LA TABLA
            showEmployeeInfo: false,
            displayMode: 'compact',
            showPageSizeSelector: true,
            showInfo: true,
            showNavButtons: true,
            showFilterRow: true,
            showHeaderFilter: true,
            currentFilter: this.applyFilterTypes[0].key,
            selectedItemKeys: [],
            //Modal
            isOpen: false,
            isOpenEdit: false,
            //Carga de datos
            data: [],
            loading: true,
        };
        this.onToolbarPreparing = this.onToolbarPreparing.bind(this);
        this.deleteUsers = this.deleteUsers.bind(this);
        this.refreshData =  this.refreshData.bind(this);
        this.isEditDisabled = this.isEditDisabled.bind(this);
        this.onEditorPreparing = this.onEditorPreparing.bind(this);
        this.onSelectionChanged = this.onSelectionChanged.bind(this);
        this.onExporting = this.onExporting.bind(this);
        this.dataGridRef = React.createRef();
    }

    /*
    *OBTIENE LA LISTA DE USUARIOS
    */
    componentDidMount() {
        this.timer = setTimeout(() => {
            this.fetchUsers();
        }, 100);
    }

    componentWillUnmount() {
        clearTimeout(this.timer);
    }

    fetchUsers() {
        const data = JSON.parse(sessionStorage.getItem('userData'));
        const token = data.AccessToken
        fetch(API_PATH + 'usuarios/varios', {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token,
        },
        })
        .then(response => {
            if (response.ok) {
              return response.json()// si el estatus code arroja un 200  continua
            } else {// si no arroja el estatus code del error
                Swal.fire({
                    title: DOMPurify.sanitize(`Error ${response.status}`),
                    icon: 'error',
                    showConfirmButton: true,
                    confirmButtonColor: '#bb2d3b',
                })
            }
          })
        .then((responseJson) => {
            this.setState({ data: responseJson, loading: false });
        })
        .catch(error => {
            throw error;
        });
    }

    //Llama a las acciones de la barra de herramientas de la tabla
    onToolbarPreparing(e) {
        e.toolbarOptions.items.unshift(
            {//Actualizar tabla
                location: 'after',
                widget: 'dxButton',
                options: {
                    icon: 'refresh',
                    onClick: this.refreshData.bind(this),
                },
            },
            {// Eliminar una fila (eliminar usuario)
                location: 'after', 
                widget: 'dxButton',
                options: {
                    icon: 'trash',
                    onClick: this.deleteUsers,        
                },  
            },
            { //Añadir usuario
                location: 'after',
                widget: 'dxButton',
                options: {
                    onClick: this.openModal,
                    // text:"+ Añadir usuario",
                    icon:'add'
                },
            },
            { //Limpiar filtros
                location: 'after',
                widget: 'dxButton',
                options: {
                    icon: 'filter',
                    onClick: this.clearFilters.bind(this),
                    text:"Limpiar filtros ",
                },
            },
        );
    }

    /*
    *ACTUALIZA TABLA DESPUES DE EDITAR O GUARDAR USUARIO
    */
    refreshDataGrid() {
    window.location.reload(false);
    }

    /*
    *ACTUALIZA LA TABLA
    */
    refreshData() {
    this.setState({
        data: []
    });
    setTimeout(() => this.fetchUsers(), 10);
    }

    //Limpiar filtros
    clearFilters = () => {
        const { current: dataGrid } = this.dataGridRef;
        if (dataGrid) {
          dataGrid.instance.clearFilter();
        }
    };


    /**
    * VERIFICAR ESTATUS Y DESACTIVA LA OPCIÓN PARA EDITAR USUARIO SI EL USUARIO ESTA INACTIVO
    */
    isChief(activo) {
        let estatus= JSON.stringify(activo)
        return estatus && ['0'].indexOf(estatus.trim()) >= 0;
    }
    isEditDisabled(e) {
        return this.isChief(e.row.data.activo);
    }

    /**
    * Abre/cierra modal con formulario para añadir un usuario
    */
    openModal = (e) => this.setState({ isOpen: true });
    closeModal = () => this.setState({ isOpen: false });
    /**
    * Abre/cierra modal con formulario para editar un usuario
    */
    openModalEdit = (e) => this.setState({ 
        isOpenEdit: true ,
        dataUser:e.row.data // obtiene la informacion por fila y se la envia al formulario para editar
    });
    closeModalEdit = () => this.setState({ isOpenEdit: false });

    /**
    * Convierte la columna Estatus en activo o inactivo el estatus del usuario, ya que lo devuelve en 1 o 0
    */
    cellRenderP(data) {
        let estatus = ( data.data.activo === 0) ? 'Inactivo' : 'Activo'
        return <span>{estatus}</span>;
    }

    /**
    * Convierte un objeto JavaScript en una cadena de texto JSON. sin usar json.stringyfy
    * haciendo uso de Object.entries() para obtener las entradas del objeto y luego recorrerlas para construir la cadena JSON manualmente
    */
    convertObjectToJsonString(obj) {
        let jsonString = '{';
        let isFirst = true;
        for (const [key, value] of Object.entries(obj)) {
          if (!isFirst) {
            jsonString += ',';
        }
        jsonString += `"${key}":`;
        if (typeof value === 'object') {
            jsonString += this.convertObjectToJsonString(value);
        } else if (typeof value === 'string') {
            jsonString += `"${value}"`;
        } else {
            jsonString += value;
        }
        isFirst = false;
        }
        jsonString += '}';
        return jsonString;
    }

    /**
    * Elimina las filas (usuarios) seleccionadas
    */
    deleteUsers() {
        const data = JSON.parse(sessionStorage.getItem('userData'));
        const token = data.AccessToken
        var deleteIds =[];
        this.state.selectedItemKeys.forEach((key) => {
            deleteIds.push(key);
        });
        Swal.fire({
            title: '¿Estás seguro de desactivar este usuario?',
            text: "Esta operación es irreversible",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#198754',
            cancelButtonColor: '#bb2d3b',
            confirmButtonText: 'Si, desactivar',
            cancelButtonText: 'Cancelar'
        })
        .then((result) => {// si confirman la desactivación del usuario
            if (result.isConfirmed) {
                // const dataBody = {
                //     uuid: deleteIds 
                // };
        
                // const jsonString = this.convertObjectToJsonString(dataBody);

                const options = {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: 'Bearer ' + token,
                    },
                    body: JSON.stringify ({
                        uuid: deleteIds 
                    })

                }
                fetch(API_PATH + 'usuarios/desactivar', options)
                .then(response => {
                    if (response.ok) {// si el estatus code arroja un 200  continua
                      return response.json()
                    } else {// si no arroja el estatus code del error
                        Swal.fire({
                            title: DOMPurify.sanitize(`Error ${response.status}`),
                            icon: 'error',
                            showConfirmButton: true,
                            confirmButtonColor: '#bb2d3b',
                        })
                    }
                })
                .then(result => {
                    if(result.responseCode === 200){// si en responseCode arroja un 200  manda mensaje de exito 
                        Swal.fire({
                            text: "El usuario se desactivó correctamente",
                            showCancelButton: false,
                            showConfirmButton: false,
                            icon: "success",
                            timer: 3000
                        })
                        this.refreshData()
                    } else{// si no arroja un 200 en responseCode de desactivar usuarios manda el detalle del error
                        Swal.fire({
                            text: DOMPurify.sanitize(`${result.detail}`),
                            icon: 'error',
                            showConfirmButton: true,
                            confirmButtonColor: '#bb2d3b',
                        })
                    }  
                })
                .catch(error => {
                    Swal.fire({
                                text: "Ocurrio un error",
                                icon: 'error',
                                showConfirmButton: true,
                                confirmButtonColor: '#bb2d3b',
                    })
                    throw error;
                });
            }
        })
    }
    
    render() {
        if (this.state.loading === true) { // muestra un loading mientras carga la página y datos de la tabla
            return(
            <div className="preloader" >
                <div className="padre-transform">
                    <div className="loader">
                        <div className="face">
                            <div className="circle"></div>
                        </div>
                        <div className="face">
                            <div className="circle"></div>
                        </div>
                    </div>
                </div>
            </div>
            );
        }
        return (
            <React.Fragment>
            <DataGrid
                ref={this.dataGridRef}
                dataSource={this.state.data}
                keyExpr="uuid"
                showBorders={true}
                hoverStateEnabled={true}
                onEditorPreparing={this.onEditorPreparing}
                onSelectionChanged={this.onSelectionChanged}
                showColumnLines={true}
                showRowLines={true}
                rowAlternationEnabled={true}
                allowColumnResizing={true}
                onToolbarPreparing={this.onToolbarPreparing}
                selectedRowKeys={this.state.selectedItemKeys}
                onExporting={this.onExporting}
                columnWidth={160}
            > 
                {/* Boton para exportar a excel */}
                <Export enabled={true} allowExportSelectedData={false} />
                <Selection
                mode="multiple"
                selectAllMode="allPages"
                showCheckBoxesMode="onClick"
                />
                {/* Buscador */}
                <SearchPanel visible={true}
                    width={200}
                    placeholder="Buscar"
                /> 
                {/* Paginación */}
                <Paging enabled={true} defaultPageSize={20}/>
                <Pager
                    visible={true}
                    allowedPageSizes={allowedPageSizes}
                    displayMode={this.state.displayMode}
                    showPageSizeSelector={this.state.showPageSizeSelector}
                    showInfo={this.state.showInfo}
                    showNavigationButtons={this.state.showNavButtons}
                />
                {/* Filtros */}
                <HeaderFilter visible={this.state.showHeaderFilter} />
                <Scrolling columnRenderingMode="virtual" />
                { /* Columnas, 
                Cambiar los nombre de datafield por como vengan en la api */}
                <Column type="buttons" caption="Editar" allowHiding={false} width={50} name="Ver más">
                    <Button  hint="Edit" icon="edit" onClick={this.openModalEdit} disabled={this.isEditDisabled} />
                </Column>
                <Column dataField="uuid" caption="Uuid" />
                <Column dataField="nombre" caption="Nombre" />
                <Column dataField="correo" caption="Correo" />
                {/* <Column dataField="nombre_grupo" caption="Tipo de usuario" /> */}
                <Column dataField="fecha_registro" caption="Fecha registro" dataType="date"/>
                <Column dataField="activo" caption="Estatus"  />
                <Column dataField="fecha_modificacion" caption="Fecha modificación"  dataType="date"/>
            </DataGrid>
            {/* Contiene el modal para añadir nuevo usuario*/}
            <Modal show={this.state.isOpen} onHide={this.closeModal}  dialogClassName="modal-90w">
                <Modal.Header closeButton >Añadir Usuario</Modal.Header>
                <RegistrarUsuarioInterno actualizar={()=>this.refreshDataGrid()}/>
            </Modal>
            {/* Contiene el modal para editar un usuario*/}
            <Modal show={this.state.isOpenEdit} onHide={this.closeModalEdit}  dialogClassName="modal-90w">
                <Modal.Header closeButton >Editar Usuario</Modal.Header>
                <EditarUsuarioInterno datosUsuario={this.state.dataUser} actualizar={()=>this.refreshDataGrid()}/>
            </Modal>
        </React.Fragment>
        );
    }
    
    /**
    * Obtiene las filas seleccionadas
    * Verifica si tiene estatus activo o no para que se desahbilite la opcion de seleccionar
    */

    isSelectable(item) {
        return item.activo;
    }
    
    isSelectAll(dataGrid) {
        let items = [];
        dataGrid.getDataSource().store().load().done(function (data) {
          items = data;
        });
        let selectableItems = items.filter(this.isSelectable);
        let selectedRowKeys = dataGrid.option("selectedRowKeys");
        if (!selectedRowKeys.length) {
          return false;
        }
        return selectedRowKeys.length >= selectableItems.length ? true : undefined;
    }
    
    onEditorPreparing(e) {
        let dataGrid = e.component;
        if (e.command === "select") {
            if (e.parentType === "dataRow" && e.row) {
                if (!this.isSelectable(e.row.data))
                    e.editorOptions.disabled = true;
                } else if (e.parentType === "headerRow") {
                    e.editorOptions.onInitialized = (e) => {
                        this.setState({
                        'selectAllCheckBox': e.component
                        })
                    };
                    e.editorOptions.value = this.isSelectAll(dataGrid);
                    e.editorOptions.onValueChanged = (e) => {
                    if (!e.event) {
                        if (e.previousValue && !this.state.checkBoxUpdating) {
                            e.component.option("value", e.previousValue);
                        }
                        return;
                    }
                    if(this.isSelectAll(dataGrid) === e.value) {
                        return;
                    }
                    e.value ? dataGrid.selectAll() : dataGrid.deselectAll();
                    e.event.preventDefault();
                }
            }
        }
    }
    
    onSelectionChanged(e) {
        let deselectRowKeys = [];
        e.selectedRowsData.forEach((item) => {
            if (!this.isSelectable(item))
                deselectRowKeys.push(e.component.keyOf(item));
        });
        if (deselectRowKeys.length) {
            e.component.deselectRows(deselectRowKeys);
        }
        this.setState({
            'checkBoxUpdating': true
        });
        if (this.state.selectAllCheckBox) {
            this.state.selectAllCheckBox.option && this.state.selectAllCheckBox.option("value", this.isSelectAll(e.component));
        }
        this.setState({
            'checkBoxUpdating': false
        });
    }  

    /**
    * Descarga la tabla en un excel
    */

    onExporting(e) {
        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet('Main sheet');
        exportDataGrid({
          component: e.component,
          worksheet,
          autoFilterEnabled: true,
        }).then(() => {
          workbook.xlsx.writeBuffer().then((buffer) => {
            saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'UsuariosInternos.xlsx');
          });
        });
        e.cancel = true;
    }
}

export default TablaUsuarioInternos;