import React from 'react';
import { withRouter, Link } from 'react-router-dom';
import axios from 'axios';
import Swal from 'sweetalert2';
import DataGrid, { Column, Pager, HeaderFilter,} from 'devextreme-react/data-grid';
import 'devextreme/dist/css/dx.softblue.css';
import LoadPanel from 'devextreme-react/load-panel';
import { API_PATH } from '../../siteConfig';

class TablaAprobarProveedores extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            // ATRIBUTOS PARA MOSTRAR PAGINACION EN LA TABLA
            showEmployeeInfo: false,
            displayMode: 'compact',
            showPageSizeSelector: true,
            showInfo: true,
            showNavButtons: true,
            showHeaderFilter: true,
            data: [],
            loading: true,
            loadingData: false,
            currentPage: 1,
            pageSize: 50,
            searchValue: '' // Nuevo estado para almacenar el valor de búsqueda
        };
        this.onToolbarPreparing = this.onToolbarPreparing.bind(this);
        this.dataGridRef = React.createRef();
    }

    //Obtiene lista de proveedores
    componentDidMount() {
        this.timer = setTimeout(() => {
            this.fetchData(this.state.currentPage);
        }, 100);
    }

    componentWillUnmount() {
        clearTimeout(this.timer);
    }

    fetchData = async (pageIndex) => {
        const data = JSON.parse(sessionStorage.getItem('userData'));
        const token = data.AccessToken;
        let url = '';
        if (this.state.searchValue) {
            // Si hay un valor de búsqueda, utilizar la URL con el parámetro razon_social
            url = API_PATH +`proveedor/?busqueda=${encodeURIComponent(this.state.searchValue)}`;
        } else if (pageIndex && this.state.pageSize) {
            // Si se ha especificado una página y un tamaño de página, utilizar la URL con paginación
            url = API_PATH + `proveedor/?page=${pageIndex}&page_size=${this.state.pageSize}`;
        } else {
            // Si no se ha especificado una página y un tamaño de página, utilizar la URL base
            url = API_PATH +'proveedor/';
        }

        this.setState({ loadingData: true });
        try {
            const response = await axios.get(url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + token,
                },
            });
            if (!response.data || response.data.length === 0) {
                // No hay datos, deshabilitar el botón de "Siguiente"
                this.setState({
                    data: [],
                    loading: false,
                    disableNextButton: true,
                });
            } else {
                this.setState({
                    data: response.data,
                    currentPage: pageIndex,
                    loading: false,
                    disableNextButton: false,
                });
            }
        } catch (error) {
            console.error('Error fetching data:', error);
            Swal.fire({
                title: 'Ocurrió un error',
                icon: 'error',
                showConfirmButton: true,
                confirmButtonColor: '#bb2d3b',
            });
        } finally {
            // Establecer loadingData en false después de recibir la respuesta o en caso de error
            this.setState({ loadingData: false });
        }
    };

    goToPage = (pageIndex) => {
        this.setState({ currentPage: pageIndex }, () => this.fetchData(pageIndex));
    };

    // Function to go to the next page
    goToNextPage = () => {
        const nextPage = this.state.currentPage + 1;
        this.goToPage(nextPage);
    };

    // Function to go to the previous page
    goToPrevPage = () => {
        const prevPage = this.state.currentPage - 1;
        if (prevPage > 0) {
            this.goToPage(prevPage);
        }
    };

    // Function to handle page number input
    handlePageNumberChange = (event) => {
        const pageNumber = parseInt(event.target.value, 10);
        if (!isNaN(pageNumber) && pageNumber > 0) {
            this.setState({ currentPage: pageNumber }, () => {
                this.fetchData(this.state.currentPage);
            });
        }
    };

    // Function to handle records per page input
    handlePageSizeChange = (event) => {
        const pageSize = parseInt(event.target.value, 10);
        if (!isNaN(pageSize) && pageSize > 0) {
            this.setState({ pageSize: pageSize }, () => this.fetchData(this.state.currentPage));
        }
    };

    // Function to handle search value change
    handleSearchValueChange = (event) => {
        const value = event.target.value;
        if (value.length >= 3) { // Verifica si el valor tiene al menos 3 caracteres
            this.setState({ searchValue: value }, () => {
                // Llamar a fetchData cada vez que cambia el valor de búsqueda
                this.fetchData(this.state.currentPage);
            });
        } else {
            // Si el valor no tiene al menos 3 caracteres, no realizar la búsqueda
            this.setState({ searchValue: value }); // Actualiza el estado sin realizar la búsqueda
        }
    };

    //Llama a las acciones de la barra de herramientas de la tabla
    onToolbarPreparing(e) {
        e.toolbarOptions.items.unshift(
            {//Actualizar tabla
                location: 'after',
                widget: 'dxButton',
                options: {
                    icon: 'refresh',
                    onClick: this.refreshData.bind(this),
                },
            },

            { //Limpiar filtros
                location: 'after',
                widget: 'dxButton',
                options: {
                    icon: 'filter',
                    onClick: this.clearFilters.bind(this),
                    text: "Limpiar filtros ",
                },
            },
        );
    }

    //ACTUALIZAR TABLA
    refreshData() {
        this.setState({
            data: []
        });
        setTimeout(() => this.fetchData(this.state.currentPage), 10);;
    }

    //Limpiar filtros
    clearFilters = () => {
        const { current: dataGrid } = this.dataGridRef;
        if (dataGrid) {
            dataGrid.instance.clearFilter();
        }
    };

    /**
    * Convierte la columna Ver más en un link hacia su información
    */
    cellRenderP(data) {
        let info = data.data
        let id = data.data.id
        let status = data.data.nombre
        let estatus = data.data.estatus
        return (
            <Link
                to={{
                    pathname: `aprobaciones/${data.data.id}`,
                    state: { info, id, status, estatus }
                }}
                className="underline bold"
            >
                <center> Ver información  <i className="fa-solid fa-angles-right"></i></center>
            </Link>
        )
    }

    render() {
        const { data, currentPage, pageSize, loadingData} = this.state;
        const pageSizeOptions = [50, 100, 150, 200];
        if (this.state.loading === true) {
            return (
                <div className="preloader" >
                    <div className="padre-transform">
                        <div className="loader">
                            <div className="face">
                                <div className="circle"></div>
                            </div>
                            <div className="face">
                                <div className="circle"></div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }

        return (
            <React.Fragment>
                {loadingData && (
                    <LoadPanel
                        shadingColor="rgba(0,0,0,0.4)"
                        position={{ of: '.my-table-container' }}
                        visible={true}
                        showIndicator={true}
                        shading={true}
                        showPane={true}
                    />
                )}
                <div className='row'>
                    <div className='col-md-4'>
                        <input
                            type="text"
                            value={this.state.searchValue}
                            onChange={this.handleSearchValueChange}
                            placeholder="Buscar por Razón Social"
                            className='form-control search-bar'
                    />
                    </div>
                </div>
                 
                <DataGrid
                    ref={this.dataGridRef}
                    dataSource={data}
                    showBorders={true}
                    hoverStateEnabled={true}
                    keyExpr="id"
                    showColumnLines={true}
                    showRowLines={true}
                    rowAlternationEnabled={true}
                    allowColumnResizing={true}
                    onToolbarPreparing={this.onToolbarPreparing}
                    allowColumnReordering={true}
                    remoteOperations={true}
                     //paging={false} Desactivar la paginación de DevExtreme
                >
                    {/* Paginación */}
                    <Pager
                        pageIndex={currentPage}
                        pageSize={pageSize}
                        onPageSizeChange={(size) => this.handlePageSizeChange(size)}
                        onPageChanged={this.handlePageChange}
                    />
                    <HeaderFilter visible={this.state.showHeaderFilter} />
                    {/* Columnas, 
                    Cambiar los nombre de datafield por como vengan en la api */}
                    <Column dataField="razon_social" caption="Razón Social" />
                    <Column dataField="rfc" caption="RFC" width={140} />
                    <Column dataField="fecha_alta" dataType="date" caption="Fecha de registro" width={100} />
                    <Column dataField="fecha_mod" dataType="date" caption="Fecha de modificación" width={100} />
                    <Column dataField="Fecha_Modificacion" dataType="date" caption="Fecha de modificación vantage" width={100} />
                    <Column dataField="status" caption="Estatus" />
                    <Column dataField="Ver más" cellRender={this.cellRenderP} allowHeaderFiltering={false} width={140} />
                </DataGrid>
                <br />
                <div className='row'>
               
                    <div className='col-md-6 col-sm-6  col-6'>
                        <select value={pageSize} onChange={this.handlePageSizeChange} className="form-select select-records" >
                            {pageSizeOptions.map((option) => (
                                <option key={option} value={option}>
                                    {option}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className='col-md-6 col-sm-6 col-6 end'>

                        <button onClick={this.goToPrevPage} className='btn  btn-ant'><i className="fa-solid fa-chevron-left"></i></button>
                        <label className='label-page'>
                            <input
                                className='form-control bg-white center'
                                value={currentPage}
                                onChange={this.handlePageNumberChange}
                            />
                        </label>
                        <button onClick={this.goToNextPage} disabled={this.state.disableNextButton} className='btn  btn-next' ><i className="fa-solid fa-chevron-right"></i></button>
                    </div>
                </div>
                <br /><br />
            </React.Fragment>
        );
    }
}

export default withRouter(TablaAprobarProveedores);