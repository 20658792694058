/**
 * PANTALLA DE INICIO PARA LOGUEO O REGISTRO
*/

import React, {Component} from 'react';
import DOMPurify from 'dompurify';
import Login from '../../login';
import logo from '../../assets/img/ipg-logo.png'
import {API_PATH } from '../../siteConfig';
import Swal from 'sweetalert2/dist/sweetalert2.js'
import 'sweetalert2/src/sweetalert2.scss'
import doc1 from '../../assets/documents/Comunicado1.pdf'
import doc2 from '../../assets/documents/Comunicado2.pdf'
import manual from '../../assets/documents/Manual_Portal_Proveedores_v2.pdf'
import { withTranslation } from 'react-i18next';

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
    data:[],
    loading: true,
    };

} 

handleChangeLanguage = event => {
  const { i18n} = this.props;
  i18n.changeLanguage(event.target.value);
};
  /**
  * Obtiene los datos del periodo de cierre
  */
    componentDidMount() {
      fetch(API_PATH + 'periodo_cierre/', {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
        },
        })
        .then(response => {
            if (response.ok) {
              return response.json()// si el estatus code arroja un 200  continua
            } else {// si no arroja el estatus code del error
                Swal.fire({
                    title: DOMPurify.sanitize(`Error ${response.status}`),
                    icon: 'error',
                    showConfirmButton: true,
                    confirmButtonColor: '#bb2d3b',
                })
                
            }
          })
          
        .then((responseJson) => {
            this.setState({ data: responseJson, loading: false });
            
        })
        .catch(error => {
            Swal.fire({
              title: `INTERNAL SERVER ERROR`,
              icon: 'error',
              showConfirmButton: true,
              confirmButtonColor: '#bb2d3b',
          })
          throw error;
        });
    }

  render() {
    let estadoPortal = this.state.data.success
    const { t } = this.props;
   

    let isVisible = ( estadoPortal === true) ? false : true;
    let warning = ( estadoPortal === true) ? true : false;
    if (this.state.loading === true) {
       // muestra un loading mientras carga la página y datos de la tabla
      return(
      <div className="preloader" >
          <div className="padre-transform">
              <div className="loader">
                  <div className="face">
                      <div className="circle"></div>
                  </div>
                  <div className="face">
                      <div className="circle"></div>
                  </div>
              </div>
          </div>
      </div>
      );
  }
  sessionStorage.setItem("portalEstado",(estadoPortal));
    return (
      <div>
        <div className="split left-login">
          <div className="centered"></div>
        </div>
        <div className="split right-login">
            <div>
              <div className='languaje'>
              {/* <LanguageSelector/> */}
              <select onChange={this.handleChangeLanguage} className='idiomas-home'>
                <option value="es">{t('Buttons.spanish')}</option>
                <option value="en">{t('Buttons.english')}</option>
              </select>

            </div>
          </div>
          <div className="centered">
            <div className="container ">
           
            <form action="#" className="form-login">
              <a href={manual} target="_blank" rel="noopener noreferrer">{t('Home.label1')} <i className="fa-solid fa-circle-question"></i></a>
              <div className='center'>
                <h1 className="title1">{t('Home.label2')}</h1>
                <h3 className="title2">{t('Home.label3')}</h3>
                <hr/><br/>
                <img src={logo} alt="logo-ipg"/><br/><br/><br/>
                {/* <button type="button" data-bs-toggle="modal" data-bs-target="#comunicadoPersonas" className='btn btn-link'> Comunicado Personas Fisicas/Morales </button>
                <button type="button" data-bs-toggle="modal" data-bs-target="#comunicadoProveedores" className='btn btn-link'> Comunicado Proveedores Servicios especializados  </button> */}
                {/* <br/><br/> */}
                <div className="d-grid gap-2">
                  {/* Manda llamar al componete de Login para SSO, que abre en popup */}
                  {isVisible ? (
                  <Login/>
                  ) : null}
                  {warning ? (
                  <div className="alert alert-dark d-flex align-items-center" role="alert">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-exclamation-triangle-fill flex-shrink-0 me-2" viewBox="0 0 16 16" role="img" aria-label="Warning:">
                      <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"/>
                    </svg>
                    <div>
                    Por cierre de mes el acceso al Portal estará inactivo 
                    </div>
                  </div>
                  ) : null}

                 <a href='./preregistro' className="btn btn-primary btn-bg">{t('Home.label7')}</a> 
                 {/* <a href='./registroinfluencer' className="btn btn-primary btn-influencer">{t('Influencer.text1')}</a>  */}
               
                </div>
              </div>
            </form>
            </div>
           
           
          </div>
          <div>
              <div className='version'>
              v.2.3.4
            </div>
          </div>
        </div>
        {/* MODAL COMUNICADO PERSONAS*/}
        <div className="modal fade" id="comunicadoPersonas" tabIndex="-1" aria-labelledby="comunicadoPersonasLabel" aria-hidden="true">
           <div className="modal-dialog modal-xl">
             <div className="modal-content">
               <div className="modal-body">
               <embed src={doc2} frameBorder="0" width="100%" height="500px"/>
               </div>
               <div className="modal-footer">
                 <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cerrar</button>
               </div>
             </div>
           </div>
         </div>
         {/* MODAL COMUNICADO PROVEEDORES*/}
         <div className="modal fade" id="comunicadoProveedores" tabIndex="-1" aria-labelledby="comunicadoProveedoresLabel" aria-hidden="true">
           <div className="modal-dialog modal-xl">
             <div className="modal-content">
               <div className="modal-body">
               <embed src={doc1} frameBorder="0" width="100%" height="500px"/>
               </div>
               <div className="modal-footer">
                 <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cerrar</button>
               </div>
             </div>
           </div>
        </div>
      </div>
      );
    }
  }

export default  withTranslation()(Home);