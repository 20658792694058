import React, {Component} from 'react';
import { withTranslation } from 'react-i18next';

class Footer extends Component {
  render() {
    const { t } = this.props;
    return (
      <footer className='footer'>
      <hr className='hr-footer' />
      <div className="container-fluid">
        <div className="footer-content">
          <ul className="no-print">&nbsp;&nbsp;&nbsp;
              <li><a href="http://www.interpublic.com/" target='blank' rel="noopener noreferrer">IPG © </a></li>
              <li><a href="http://www.interpublic.com/about" target='blank' rel="noopener noreferrer" className="first">{t('Footer.nosotros')}</a></li>
              <li><a href="http://www.interpublic.com/our-agencies" target='blank' rel="noopener noreferrer">{t('Footer.agencias')}</a></li>
              <li><a href="http://www.interpublic.com/ideas" target='blank' rel="noopener noreferrer">{t('Footer.ideas')}</a></li>
              <li><a href="http://www.interpublic.com/news" target='blank' rel="noopener noreferrer">{t('Footer.noticias')}</a></li>
              <li><a href="http://www.interpublic.com/diversity" target='blank' rel="noopener noreferrer">{t('Footer.diversidad')}</a></li>
              <li><a href="http://www.interpublic.com/contact" target='blank' rel="noopener noreferrer">{t('Footer.contacto')}</a></li>
              {/* <li><a href="https://ipgproveedores.com.mx/js/ViewerJS#/documents/Ayuda.pdf" target="_blank" rel="noopener noreferrer">{t('Footer.ayuda')}</a></li> */}
              <li>&nbsp; © 2024</li>
          </ul>
          <span className="footer-text">v.2.4.0</span>
        </div>
      </div>
    </footer>
    );
  }
}

export default withTranslation()(Footer);